import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import styled from '@emotion/styled';
import Layout from '../components/Layout';
import { Seo } from '../components/Seo';
import { Button } from '../components/Button';
import { CommonBlock } from '../components/CommonBlock';
import { CommonContent } from '../components/CommonContent';
import { RecentArticle } from '../components/RecentArticle';
import { META_INFO } from '../utils/word';

export default ({ data, location }): JSX.Element => (
  <Layout>
    <Seo pagetitle={META_INFO.ERROR.TITLE} pagepath={location.pathname} />

    <CommonContent>
      <Error>
        <CommonBlock>
          <ErrorInner>
            <ErrorTitle>
              <SPAN_ErrorTitleText>お探しのページが</SPAN_ErrorTitleText>
              <SPAN_ErrorTitleText>見つかりません</SPAN_ErrorTitleText>
            </ErrorTitle>

            <FIGURE_ImageWrapper>
              <Img
                fluid={data.errorImage.childImageSharp.fluid}
                alt=""
                style={{ height: '100%' }}
              />
            </FIGURE_ImageWrapper>
          </ErrorInner>
        </CommonBlock>
      </Error>

      <ArticleBlockWrapper>
        <CommonBlock title="Recent Article">
          <RecentArticle />
          <DIV_ButtonWrap>
            <Button>
              <Link to="/article/">記事一覧</Link>
            </Button>
          </DIV_ButtonWrap>
        </CommonBlock>
      </ArticleBlockWrapper>
    </CommonContent>
  </Layout>
);

const Error = styled.div`
  padding: 2vh 0;
`;

const ErrorInner = styled.div`
  padding: 40px 0 20px;
`;

const ErrorTitle = styled.h1`
  text-align: center;
  font-size: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const SPAN_ErrorTitleText = styled.span``;

const ArticleBlockWrapper = styled.div`
  margin-top: 40px;
`;

const FIGURE_ImageWrapper = styled.figure`
  max-width: 400px;
  max-height: 400px;
  margin: 40px auto 0;

  img {
    object-fit: cover;
  }
`;

const DIV_ButtonWrap = styled.div`
  max-width: 300px;
  margin: 20px auto 0;
`;

export const query = graphql`
  query {
    errorImage: file(relativePath: { eq: "img_404.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
